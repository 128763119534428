import React, { useState, useEffect } from 'react';
import Nav from './Nav';
import { Link } from 'gatsby';

export default function SideBar({ fullMenu }) {
  const [headerOpen, toggleHeader] = useState(false);

  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }
  });

  return (
    <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
      <div className="header--title">
        <h1>
          <Link to="/">Denny &amp; Kathy</Link>
        </h1>
      </div>
      <div className={`header--second-line ${scrolled ? 'scrolled' : ''}`}>
        <Link
          to="/"
          className={`header--second-line--title${
            scrolled ? '--scrolled' : ''
          } fade-in`}
        >
          Denny &amp; Kathy
        </Link>
        <div className="header--second-line--menu">
          <Link className="header--second-line--menu--item" to="/">
            Home
          </Link>
          <Link className="header--second-line--menu--item" to="/our-story">
            Our Story
          </Link>
          <Link className="header--second-line--menu--item" to="/events">
            Schedule of Events
          </Link>
          <Link className="header--second-line--menu--item" to="/photos">
            Photos
          </Link>
          {/* <Link className="header--second-line--menu--item" to="/registry">
            Registry
          </Link> */}
          {/* <Link className="header--second-line--menu--item" to="/rsvp">
            RSVP
          </Link> */}
        </div>
      </div>

      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </header>
  );
}
